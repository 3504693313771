<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25" style="font-size:12px;">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.cloudmedia.hk"
        target="_blank"
      >Cloud Media IT Solution Ltd</b-link>
      <span class="d-sm-inline-block">, All rights Reserved . <b>version {{app_version}}</b></span>
    </span>

  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  data() {
    return {
        app_version : process.env.VUE_APP_VERSION
    }
  },
  created() {
  //  console.log(process.env.VUE_APP_VERSION)
    console.log(this.app_version)
  }
}
</script>
