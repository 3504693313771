<template>
  <li
    v-if="canViewVerticalNavMenuLink(item) && check_module(item.module)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <feather-icon v-if="item.iconPack == 'feather'" :icon="item.icon || 'CircleIcon'" />
      <font-awesome-icon v-if="item.iconPack == 'fontawesome'" :icon="item.icon" />
      <span class="menu-title text-truncate"> {{ t(item.title) }} </span>
      <b-badge
        v-if="item.tag"
        pill
        :variant="item.tagVariant || 'primary'"
        class="mr-1 ml-auto"
      >
        {{ item.tag }} 
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge,
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      client_module : JSON.parse(localStorage.getItem('userData')).module
    }
  },
  created() {
    console.log(this.client_module)
  },
  methods: {
    check_module(module) {
      //console.log(module + " - " +  this.client_module.includes(module))
      if (module === undefined || this.client_module.includes(module) === true ) { 
        return true
      }
    }
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },

}
</script>
